.fc-daygrid-dot-event {
  background-color: $primary;
}

td.fc-list-event-time {
  display: none;
}

.fc-list-table {
  .event-item-holder {
    .event-item {
      color: #000000;
      cursor: pointer;
    }
  }
}

.payment-status-badge {
  min-width: 95px;
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  background: $primary !important;
  opacity: 0.8;
}

.event-item-holder {
  .event-item {
    text-align: left;
    color: #ffffff;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
}

.top-card-bg {
  background-size: cover !important;
  background-color: #64a233;
}

.image-holder {
  margin: 1rem;
  img {
    max-width: 100%;
  }
}

.dropdown-menu .dropdown-item {
  .d-link {
    width: 100%;
    display: block;
  }
}
